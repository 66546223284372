import { FC } from 'react';
import { StyledContainer } from '../../styled';
import { TemplateCard, NoData, LoaderDots } from '@/components';
import { useAppSelector } from '@/hooks/redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppDispatch } from '@/hooks/redux';

import { getTemplateListThank } from '@/store/reducers/TemplatesSlice/AsyncThanks';
import { getLabsList } from '@/store/reducers/LabsSlice';



export const ActiveLabList = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getTemplateListThank());
        // Add any other fetch actions you need here
    }, []); // Empty dependency array means this runs once when component mounts


    const { templates=[], templatesState: { fetching:templatesFetching }} = useAppSelector((state) => state.TemplatesSlice);
    
    const {
        progressLabState: { data:labs=[], fetching: labsFetching }    } = useAppSelector((state) => state.LabListsSlice);

    // Filter templates to find ones with active labs
    const templatesWithActiveLabs = templates.filter(template => template.active_labs > 0);
    const hasActiveLabs = templatesWithActiveLabs.length > 0;
    
    if (templatesFetching || labsFetching) {
        return <LoaderDots />;
    }

    if (!hasActiveLabs) {
        return (
            <NoData
                title="There are no active labs"
                text="Start a lab and use this tab to quckly return to your lab"
                style={{ paddingBottom: '128px' }}
            />
        )
    };

    return (
        <>
            {templatesWithActiveLabs.map((template) => {
                // Find corresponding lab data
                const labData = labs.find(lab => lab.lab.id === template.id);
                if (!labData) { return (
                    <NoData
                        title="There are no active labs"
                        text="Start a lab and use this tab to quckly return to your lab"
                        style={{ paddingBottom: '128px' }}
                    />
                ) 
            }

                return (
                    <TemplateCard 
                        {...labData} 
                        key={template.id}
                    />
                );
            })}
        </>
    );
};
