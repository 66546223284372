import { PagesTabs } from '@/components';
import { UnfinishedLabList, InfluencerLabList } from './components';
import { StyledContainer } from './styled';
import { useState } from 'react';
import { useAppSelector } from '@/hooks/redux';
import { ActiveLabList } from './components/ActiveLabList/ActiveLabList';

export const LabList = () => {
    const [tabId, setTabId] = useState(0);


    const tabData = [
        {
            value: 'Unfinished labs',
            id: 0,
        },
        {
            value: 'Influencers choice',
            id: 1,
        },
        {
            value: 'Active labs',
            id: 2,
        }
    ];

    return (
        <StyledContainer>
            <PagesTabs
                style={{ paddingLeft: '16px' }}
                onChange={(id) => setTabId(id as number)}
                data={tabData}
            />
            {(() => {
                switch (tabId) {
                    case 0:
                        return <UnfinishedLabList />;
                    case 1:
                        return <InfluencerLabList />;
                    case 2:
                        return <ActiveLabList />;
                    default:
                        return <UnfinishedLabList />;
                }
            })()}
        </StyledContainer>
    );
};

