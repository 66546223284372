import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { baseColors } from '@/constants';

interface SideBarProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    children: ReactNode;
}

export const SideBar: FC<SideBarProps> = ({ isOpen, onClose, title, children }) => {
    if (!isOpen) return null;

    return (
        <StyledOverlay onClick={onClose}>
            <StyledSideBar onClick={(e) => e.stopPropagation()}>
                <StyledHeader>
                    <StyledTitle>{title}</StyledTitle>
                    <StyledCloseButton onClick={onClose}>&times;</StyledCloseButton>
                </StyledHeader>
                <StyledContent>
                    {children}
                </StyledContent>
            </StyledSideBar>
        </StyledOverlay>
    );
};

const StyledOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: flex-end;
    z-index: 1000;
`;

const StyledSideBar = styled.div`
    width: 400px;
    height: 100%;
    background: ${baseColors.black(1)};
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
    padding: 20px;
    display: flex;
    flex-direction: column;
`;

const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

const StyledTitle = styled.h2`
    margin: 0;
    font-size: 1.5rem;
`;

const StyledCloseButton = styled.button`
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 5px;
    
    &:hover {
        opacity: 0.7;
    }
`;

const StyledContent = styled.div`
    flex: 1;
    overflow-y: auto;
`;