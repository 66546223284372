import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { apiClient } from '@/api/apiClient';
import { IError } from '@/types';
import { IProgressLabResponseData, IState } from '../types';

export const getProgressLabsThunk = createAsyncThunk<
    IProgressLabResponseData,
    void,
    { rejectValue: IError }
>('labListsSlice/getProgressLabsThunk', async (_, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(
            'api/v1/lab_progress/'
        );
        const data = await response.data;
        return data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.response?.status,
                message: err.response?.data.detail,
            });
        }
    }
});

export const getProgressLabsExtraReducer = (
    builder: ActionReducerMapBuilder<IState>
) => {
    builder.addCase(getProgressLabsThunk.pending, (state) => {
        state.progressLabState.fetching = true;
        state.progressLabState.errors = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(getProgressLabsThunk.fulfilled, (state, action) => {
        const { results } = action.payload;
        state.progressLabState = {
            ...action.payload,
            data: results,
            fetching: false,
            errors: {
                code: undefined,
                message: undefined,
            },
        };
    });
    builder.addCase(getProgressLabsThunk.rejected, (state, action) => {
        state.progressLabState = {
            ...state.progressLabState,
            fetching: false,
            errors: action.payload as IError,
        };
    });
};
